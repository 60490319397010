import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Logo from 'assets/images/logo-vertical.svg'

export const DenyDoctorActivationModal = ({
  isOpen,
  centered = true,
  onHide = () => {},
  onCancel,
  onSuccess,
  onDataChange
}) => {
  const { t } = useTranslation(['translation'])
  return <Modal
    aria-labelledby = 'contained-modal-title-vcenter'
    show            = { isOpen }
    onHide          = { onHide }
    centered        = { centered }
    >
    <Modal.Body className = 'text-center'>
      <img alt = '' src = {Logo} />
      <div className = 'message'>
        { t('translation:GENERIC:DOCTOR_REJECT_CONFIRMATION') }
      </div>

      <Form.Control
        as     = 'textarea'
        rows   = { 5 }
        onBlur = { evt => onDataChange('reason', evt.currentTarget.value)} />

      <Button
        variant = 'primary'
        onClick = { onSuccess }
      >{ t('translation:GENERIC:SEND_BTN') }</Button>

      <Button
        variant = 'light'
        onClick = { onCancel }
      >{ t('translation:GENERIC:CANCEL_BTN') }</Button>

    </Modal.Body>
  </Modal>
}

export const ApproveDoctorActivationModal = ({
  isOpen,
  centered = true,
  onHide = () => {},
  onCancel,
  onSuccess
}) => {
  const { t } = useTranslation(['translation'])
  return <Modal
    show   = { isOpen }
    onHide = { onHide }
    aria-labelledby = 'contained-modal-title-vcenter'
    centered = { centered }
    >
    <Modal.Body className = 'text-center'>
      <img alt = '' src = {Logo} />
      <div className = 'message'>
        { t('translation:GENERIC:DOCTOR_APPROVE_CONFIRMATION') }
      </div>
      <Button variant='success' onClick={onSuccess}>{ t('translation:GENERIC:APPROVE_BTN') }</Button>
      <Button variant='light' onClick={onCancel}>{ t('translation:GENERIC:CANCEL_BTN') }</Button>
    </Modal.Body>
  </Modal>
}
