import { ACTION_TYPES } from 'reducers/stats'

export const getStats = (fromDate, toDate) => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.STATS_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctors/stats',
        method  : 'get',
        headers : {
          Authorization: `Bearer ${token}`
        },
        params : {
          expanded : 0,
          fromDate,
          toDate
        }
      })
      dispatch({
        type: ACTION_TYPES.STATS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.STATS_FAIL,
        error
      })
    }
  }
}