import ui from './ui'
import auth from './auth'
import languages from './languages'
import doctor from './doctors'
import doctorProfile from './doctorProfile'
import doctorsStats from './doctorsStats'
import doctorStats from './doctorStats'
import stats from './stats'
import activeDoctors from './activeDoctors'

const Reducers = {
  ui,
  auth,
  languages,
  doctor,
  doctorProfile,
  doctorStats,
  doctorsStats,
  stats,
  activeDoctors
}

export default Reducers
