import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

import SideMenuIcon from 'assets/images/icons/hamburger-icon.svg'
import AppLogo from 'assets/images/logo.svg'

import i18n from 'utils/i18n/'
import { getLabelsHO } from 'utils/'
import { uiToggleMenu } from 'actions/menu'
import { setCurrentLanguage } from 'actions/language'

const getLabels = getLabelsHO(useTranslation)

const TopBar = ({ toggleSideMenu, changeLanguage }) => {
	const languages = useTranslation(['languages'])
	  .t('languages:LIST')
		.map(({ value, label }, idx) => <NavDropdown.Item
			key     = { `lang-el-item-${idx}` }
			onClick = { () => changeLanguage(value) }>{ label }</NavDropdown.Item>)

	const NavDropdownOptions = {
		title     : getLabels()['LANG'],
		id        : 'nav-lang-dropdown',
		className : 'justify-content-end'
	}
  return <Navbar expand='lg' collapseOnSelect fixed = 'top'>
		{/* <Navbar.Toggle aria-controls='responsive-navbar-nav' /> */}
		<Nav>
			<Link to='#' className='menu-bars navbar-text'>
				<img alt = '' src = { SideMenuIcon } onClick = { toggleSideMenu } />
			</Link>
			<Navbar.Brand href='/'>
				<img alt = '' className='logo' src = { AppLogo } />
			</Navbar.Brand>
		</Nav>
		<Nav className='ml-auto'>
			<NavDropdown { ...NavDropdownOptions }>
				{ languages }
			</NavDropdown>
		</Nav>
	</Navbar>
}

export const mapDispatchToProps = dispatch => ({
	toggleSideMenu : () => dispatch(uiToggleMenu()),
	changeLanguage : lang => {
    localStorage.setItem('lang', lang)
		dispatch(setCurrentLanguage(lang))
		i18n.changeLanguage(lang)
	}
})

export default connect(
  null,
  mapDispatchToProps
)(TopBar)
