import { Alert, Container, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Loader = ({ fluid = true, message, isLoading = false, isFetching = false, children }) => {
  const { t }  = useTranslation(['translation'])
  if (isLoading || isFetching)
    return <Container fluid = { fluid }>
      <Alert variant='primary'>
        <Spinner animation='border' size='sm' role='status' variant='primary' >
          <span className='sr-only'>{message || t('translation:GENERIC:DATA_FETCHING_MESSAGE')}</span>
        </Spinner>
        {' '}
        { message || t('translation:GENERIC:DATA_FETCHING_MESSAGE') }
      </Alert>
    </Container>
  return children
}

export default Loader