const defaultState = {
  isAuthorized: false,
  token: null,
  user: null
}

export const ACTION_TYPES = {
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  RESET_AUTH_TOKEN: 'RESET_AUTH_TOKEN'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.RESET_AUTH_TOKEN:
      return {
        ...state,
        token: null,
        isAuthorized: false,
        user: null
      }
    case ACTION_TYPES.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.token,
        isAuthorized: true,
        user: action.user
      }
    default:
      return state
  }
}