import React, { Component } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Row, Container, Button, Spinner, Alert } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

import { getDoctorProfile, activateDoctor, deactivateDoctor } from 'actions/doctorProfile'

import PersonalInfo from './personalInfo.jsx'
import Studies from './studies.jsx'
import Specialty from './specialty.jsx'
import WorkExperience from './workExperience.jsx'
import Footer from 'components/ui/footer'
import {
  DenyDoctorActivationModal,
  ApproveDoctorActivationModal
} from 'components/ui/common/modals'
import Loader from 'components/ui/common/loader'
import BackIcon from 'assets/images/icons/back-icon.svg'

const BackPage = ({ label }) => {
  const history = useHistory()
  return <div className = 'col-6' onClick = { () => history.goBack() }>
    <img alt = '' src = { BackIcon } className = 'back' />
    { label }
  </div>
}

class DoctorProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDenyModalOpen    : false,
      isApproveModalOpen : false
    }
    this.props.dispatch(getDoctorProfile(this.props.match.params.id))
  }

  render() {
    const DOCTOR_ID = this.props.match.params.id
    const { doctorProfile, t } = this.props

    // if (doctorProfile.isFetching || !doctorProfile.data) {
    //   return <Loader {...doctorProfile} />
    // }

    const toggleModal = type => {
      const stateData = {
        data: null
      }
      if (type === 'deny') {
        stateData['isDenyModalOpen']    = !this.state.isDenyModalOpen
        stateData['isApproveModalOpen'] = false
      } else {
        stateData['isApproveModalOpen'] = !this.state.isApproveModalOpen
        stateData['isDenyModalOpen']    = false
      }
      this.setState({
        ...this.state,
        ...stateData
      })
    }

    const isPerformingAction = doctorProfile.activation.isFetching || doctorProfile.deactivation.isFetching
    const isActionCompleted  = doctorProfile.activation.data || doctorProfile.deactivation.data

    const profile = doctorProfile.data?.profile

    if (doctorProfile.data && doctorProfile.data.isEmpty) {
      return <Container fluid>
        <Alert variant='warning'>{ t('translation:GENERIC:DOCTOR_PROFILE_INCOMPLETE') }</Alert>
      </Container>
    }

    return <>
      <Container fluid>

        { isActionCompleted
          ? <Alert variant='success'>{ t('translation:GENERIC:PROFILE_UPDATE_SUCCESS') }</Alert>
          : null
        }

        <Card className = 'plain-header'>
          <Card.Header>
            <Row>
              <Loader isLoading = { !profile }>
                <BackPage label = { t('translation:GENERIC:BACK') } />
                <h5 className='col-6 profile-header'>
                  { t('translation:GENERIC:DOCTOR_PREFIX') } {profile?.firstName} {profile?.lastName}
                </h5>
              </Loader>
            </Row>
          </Card.Header>

          <Loader isLoading = { !profile }>

            <PersonalInfo className = 'personal-info-header' { ...profile }/>
            <Studies        educations = { profile?.education }/>
            <Specialty      specialties = { profile?.speciality }/>
            <WorkExperience employments = { profile?.employmentHistory }/>

            <Card.Footer>
              <div className='profile-request-buttons text-right'>

                <Button
                  disabled  = { isPerformingAction }
                  className = 'rounded'
                  variant   = 'outline-danger'
                  onClick   = {() => toggleModal('deny')}
                >
                  { doctorProfile?.deactivation.isFetching
                    ? <Spinner animation='border' size='sm' role='status' variant='danger' />
                    : null
                  }
                  { t('translation:GENERIC:DECLINE_REQUEST') }
                </Button>
                { profile?.activation.activated
                  ? null
                  : <Button
                      disabled  = { isPerformingAction }
                      className = 'rounded'
                      variant   = 'success'
                      onClick   = {() => toggleModal('success')}
                    >
                      { doctorProfile?.activation.isFetching
                        ? <Spinner animation='border' size='sm' role='status' variant='light' />
                        : null
                      }
                      { t('translation:GENERIC:APPROVE_REQUEST') }
                    </Button>
                }
              </div>
            </Card.Footer>
          </Loader>
        </Card>

        <Footer />

      </Container>
      {
        this.state.isApproveModalOpen
        ? <ApproveDoctorActivationModal
            isOpen    = { this.state.isApproveModalOpen }
            onSuccess = { () => {
              this.props.dispatch(activateDoctor(DOCTOR_ID))
              toggleModal('success')
            }}
            onCancel  = { () => {
              toggleModal('success')
            }}
          />
        : null
      } {
        this.state.isDenyModalOpen
        ? <DenyDoctorActivationModal
            isOpen       = { this.state.isDenyModalOpen }
            onDataChange = { (key, value) => {
                const state = this.state
                const data = {}
                data[key] = value
                this.setState({
                  ...state,
                  data: {
                    ...state.data,
                    ...data
                  }
                })
              }
            }
            onSuccess    = { () => {
              this.props.dispatch(deactivateDoctor(DOCTOR_ID, this.state.data.reason))
              toggleModal('deny')
            }}
            onCancel     = { () => {
              toggleModal('deny')
            }}
          />
        : null
      }
    </>
  }
}

const mapStateToProps = ({ doctorProfile }) => ({ doctorProfile })

export default connect(mapStateToProps)(withTranslation()(DoctorProfile))
