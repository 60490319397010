import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FaRegCopyright } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'

const Footer = ({ size, color, className, children }) => <Row className = {`${className} footer`}>
  <Col><FaRegCopyright /> {new Date().getFullYear()} LeoDoc Inc.</Col>
  <Col className='text-right'><Button><BiSupport /></Button></Col>
  {children}
</Row>

export default Footer