import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getLabelsHO } from 'utils/'

import WorkExperienceIcon from 'assets/images/icons/workExperience-icon.svg'
import CheckMarkIcon from 'assets/images/icons/checkMark-icon.svg'
import Documents from './documents'

const getLabels = getLabelsHO(useTranslation)

const WorkEntry = ({ title, periodOfWork, workingNow, documents = [], address }) => {
	const { t }      = useTranslation(['translation'])
	const LABELS     = t('translation:GENERIC')
	const FORMATTING = t('translation:FORMATTING')

	const startDate = moment.utc(periodOfWork.from).format(FORMATTING['DATE_FORMAT'])
  let endDate = null
  if (!workingNow) {
    endDate = moment.utc(periodOfWork.to).format(FORMATTING['DATE_FORMAT'])
  }

	const experienceDuration = moment.duration(moment(periodOfWork.to || new Date()).diff(moment(periodOfWork.from)))

	return  <div className='doctor-profile-info-box'>
		<Row>
			<Col md={4}>
				<div className='profile-image'>
					<img
            alt       = ''
						src       = { WorkExperienceIcon }
						className = 'round' />
				</div>
				<div className='section-header'>
          { title }
				</div>
				{ address }
			</Col>
			<Col md={2}>
				<div className='section-subheader'>
					{ LABELS['WORK_START_DATE'] }
				</div>
				{startDate}
			</Col>
			<Col md={2}>
				<div className='section section-subheader'>
					{ workingNow ? LABELS['WORKING_NOW'] : LABELS['END_DATE']}
				</div>
				{workingNow ? <><img alt = '' src={CheckMarkIcon} /> {LABELS['WORKING_NOW']}</> : endDate }
			</Col>
      <Col>
        <div className='section-subheader'>
          { LABELS['EXPERIENCE'] }
        </div>
        {experienceDuration.years()} { LABELS['YEARS'] } {experienceDuration.months()} { LABELS['MONTHS'] }
      </Col>
		</Row>
    { documents && documents.length > 0
      ? <>
          <hr />
          <Documents documents = { documents } />
        </>
      : null
    }
	</div>
}

const WorkExperience = ({ employments }) => {
  const { t } = getLabels()
	return <div className='doctor-profile'>
		<h5 className='header'>{t('WORK_EXPERIENCE')}</h5>
		{employments.map((employment, index) => <WorkEntry key={index} {...employment}/>)}
	</div>
}

export default WorkExperience

