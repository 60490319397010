import { ACTION_TYPES } from 'reducers/doctors'

export const getDoctorsList = () => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.GET_DOCTORS_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctors',
        method  : 'get',
        headers : {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: ACTION_TYPES.SET_DOCTORS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.SET_DOCTORS_FAIL,
        error
      })
    }
  }
}

export const getNotApprovedDoctors = () => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.DOCTOR_APPROVALS_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctors/new',
        method  : 'get',
        headers : {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: ACTION_TYPES.DOCTOR_APPROVALS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.DOCTOR_APPROVALS_FAIL,
        error
      })
    }
  }
}
