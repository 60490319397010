import React from "react";
import { Row, Col } from "react-bootstrap";

import { useTranslation } from 'react-i18next'

import StudiesIcon from 'assets/images/icons/studies-icon.svg'
import Documents from './documents'

const StudyEntry = ({ department, currentPlaceOfWork, specialty, gradeLevel, documents = [] }) => {
  const { t }  = useTranslation(['translation', 'grades'])
  const LABELS = t('translation:GENERIC')
  const GRADES = t('grades:MAP')

  return <div className='doctor-profile-info-box'>
    <Row>
      <Col md={4}>
        <div className='profile-image'>
          <img
            alt       = ''
            src       = { StudiesIcon }
            className = 'round'
          />
        </div>
        <div>
          <div className='section-header'>
            {currentPlaceOfWork}
          </div>
          {department}
        </div>
      </Col>
      <Col>
        <div className='section-subheader'>
          {LABELS['SPECIALTY']}
        </div>
        {specialty}
      </Col>
      <Col>
        <div className='section-subheader'>
          {LABELS['GRADE']}
        </div>
        {GRADES[gradeLevel]}
      </Col>
      <Col></Col><Col></Col>
    </Row>
    { documents && documents.length > 0
      ? <>
          <hr />
          <Documents documents = { documents } />
        </>
      : null
    }
  </div>
}

const Studies = ({educations}) => {
  const { t }  = useTranslation(['translation'])
  const LABELS = t('translation:GENERIC')
	return <div className='doctor-profile'>
    <h5 className="header">{LABELS['STUDIES']}</h5>
    {
      educations.map((e, index) => <StudyEntry key={`study-entry-${index}`} {...e}/>)
    }
  </div>
}

export default Studies
