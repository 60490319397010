import { ACTION_TYPES } from '../reducers/languages'

export const setCurrentLanguage = current => ({
  type: ACTION_TYPES.SET_CURRENT_LANGUAGE,
  current
})

export const setLanguageList = list => ({
  type: ACTION_TYPES.SET_LANGUAGE_LIST,
  list
})