export const config = {
  auth0Domain: 'login.leodoc.com',
  auth0Audience: 'http://localhost:7500',
  auth0ClientId: 'TSfUrPgpW6Y18UYzldZcWPmqBRaa7hBp',
  auth0RedirectURI: window.location.origin,
  server: {
    // host: 'http://localhost:3000'
    // host: 'https://dev1.api.leodoc.com'
    // host: 'https://staging.api.leodoc.com'
    host: 'https://api.leodoc.com'
  },
  serverScope: ''
}