import React from 'react'
import {
	Card,
	Table
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import DoctorAvatar from 'components/ui/common/avatar'
import { getActiveDoctors } from 'actions/activeDoctors'
import Loader from 'components/ui/common/loader'
import Specialty from 'components/ui/common/specialty'
import NoData from 'components/ui/common/noData'
import ProfileIcon from 'assets/images/icons/profile.svg'


const DoctorRow = ({ _id, previewProfile, specialty }) => {
	const history = useHistory()
	const { t }   = useTranslation(['translation'])

	const goToProfile = () => history.push(`/doctor-profile/${_id}`)

	return <tr onClick = { goToProfile }>
		<td>
			<DoctorAvatar
				avatar = { previewProfile.avatar }
				gender = { '' }
				name   = { `${previewProfile.firstName} ${previewProfile.lastName}` }
			/>
		</td>
		<td className='doctor-specialty-list'>
			{
				specialty.map((s, idx) => <Specialty key={`dli-sp-${_id.substr(-5,5)}-${idx}`} type = { s } />)
			}
		</td>
		<td>
			N/A
		</td>
    <td>
			<span>
        <img alt = '' src = { ProfileIcon } /> { t('translation:GENERIC:VIEW_PROFILE') }
      </span>
		</td>
	</tr>
}

const Consultations = ({ category }) => {
  const { t }    = useTranslation(['translation'])
  const dispatch = useDispatch()
  const list     = useSelector(s => s.activeDoctors)

  if (!list.isLoaded && !list.isFetching) {
    dispatch(getActiveDoctors())
  }

  let statsData = list.data
  if(category && category.trim() != '') {
    statsData = list.data.filter(({ specialty }) => specialty.includes(category))
  }

	return <Card >
			<Card.Header>
				<h5>{ t('translation:GENERIC:ACTIVE_CONSULTATIONS') }</h5>
			</Card.Header>
			<Card.Body>
        <Loader {...list}>
          <NoData data = { statsData } wrapper = { false } >
            <Table hover>
              <thead>
                <tr>
                  <th>{ t('translation:GENERIC:DOCTOR') }</th>
                  <th>{ t('translation:GENERIC:SPECIALITY') }</th>
                  <th>{ t('translation:GENERIC:DURATION') }</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {
                  statsData.map(el => <DoctorRow key = {`dli-${el._id.substr(-10,10)}`} {...el} />)
                }
              </tbody>
            </Table>
          </NoData>
        </Loader>
			</Card.Body>
		</Card>
}

export default Consultations
