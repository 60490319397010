import React from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SidebarData } from 'mocks/menu.jsx'
import { resetAuthToken } from 'actions/auth'

const MenuListItem = ({ item: { className, path, icon }, label }) => {
	const history = useHistory()
  return <li className = { className } onClick = {() => history.push(path)} >
    <span>
      {icon} <span className='menu-label'>{ label }</span>
    </span>
  </li>
}

const logoutUser = logout => () => {
  resetAuthToken()
  logout({ returnTo: window.location.origin })
}

const Menu = ({ isCollapsed }) => {
  const { t }      = useTranslation(['translation'])
  const { logout } = useAuth0()
  const LABELS     = t('translation:GENERIC')
	return (
		<>
      <div id='sidebar-menu'>
        <nav className={isCollapsed ? 'nav-menu' : 'nav-menu active'}>
          <ul className='nav-menu-items'>{
            SidebarData.map(
              (item, index) => <MenuListItem key = { `menu-item-${index}` } item = { item } label = { LABELS[item.label] } />
            )
          }
           <li className = 'menu-button' onClick={logoutUser(logout)} >
              <RiLogoutBoxRLine /> <span className='menu-label'>{LABELS['LOGOUT']}</span>
          </li>
          </ul>
        </nav>
      </div>
		</>
	)
}

export default connect(({ ui: { menu }}) => menu)(Menu)
