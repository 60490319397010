const defaultState = {
  doctors: {
    isLoaded: false,
    isFetching: false,
    data: [],
    error: null
  },
  doctorApprovals: {
    isLoaded: false,
    isFetching: false,
    data: [],
    error: null
  },
  doctorProfile: {
    isFetching: false,
    isLoaded: false,
    data: null,
    error: null
  }
}

export const ACTION_TYPES = {
  GET_DOCTORS_REQ      : 'GET_DOCTORS_REQ',
  SET_DOCTORS_SUCC     : 'SET_DOCTORS_SUCC',
  SET_DOCTORS_FAIL     : 'SET_DOCTORS_FAIL',

  DOCTOR_APPROVALS_REQ  : 'DOCTOR_APPROVALS_REQ',
  DOCTOR_APPROVALS_SUCC : 'DOCTOR_APPROVALS_SUCC',
  DOCTOR_APPROVALS_FAIL : 'DOCTOR_APPROVALS_FAIL'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.GET_DOCTORS_REQ:
      return {
        ...state,
        doctors: {
          isFetching : true,
          isLoaded   : false,
          data       : [],
          error      : null
        }
      }
    case ACTION_TYPES.SET_DOCTORS_SUCC:
      return {
        ...state,
        doctors: {
          isFetching : false,
          isLoaded   : true,
          data       : action.data,
          error      : null
        }
      }
    case ACTION_TYPES.SET_DOCTORS_FAIL:
      return {
        ...state,
        doctors: {
          isFetching : false,
          isLoaded   : false,
          data       : [],
          error      : action.error
        }
      }
    case ACTION_TYPES.DOCTOR_APPROVALS_REQ:
      return {
        ...state,
        doctorApprovals: {
          isFetching : true,
          isLoaded   : false,
          data       : [],
          error      : null
        }
      }
    case ACTION_TYPES.DOCTOR_APPROVALS_SUCC:
      return {
        ...state,
        doctorApprovals: {
          isFetching : false,
          isLoaded   : true,
          data       : action.data,
          error      : null
        }
      }
    case ACTION_TYPES.DOCTOR_APPROVALS_FAIL:
      return {
        ...state,
        doctorApprovals: {
          isFetching : false,
          isLoaded   : false,
          data       : [],
          error      : action.error
        }
      }
    default:
      return state
  }
}