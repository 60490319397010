import React from 'react'
import { Route } from 'react-router-dom'
import Dashboard from 'components/pages/dashboard/'
import DoctorProfile from 'components/pages/doctorProfile/'
import DoctorStats from 'components/pages/doctorStats/'
import Doctors from 'components/pages/doctors/'
import DoctorsStats from 'components/pages/doctorsStats/'
import DoctorApprovals from 'components/pages/doctorApprovals/'

const Routes = () => {
	return <div className='content-body'>
    <Route path='/' exact component={Dashboard} />
    <Route path='/doctors' component={Doctors} />
    <Route path='/doctors-stats' component={DoctorsStats} />
    <Route path='/doctor-profile/:id' component={DoctorProfile} />
    <Route path='/doctor-stats/:id' component={DoctorStats} />
    <Route path='/doctor-approvals' component={DoctorApprovals} />
  </div>
}

export default Routes
