import React from 'react'

const Dot = ({ size, color, className, children }) => <span
    style={
      {
        width  : `${size}px`,
        height : `${size}px`
      }
    }
    className = {`dot dot-${color} ${className}`}
  >{children}</span>

export default Dot