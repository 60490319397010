import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import DashboardCharts from './charts'
import ActiveConsultations from './activeConsultations'
import Footer from 'components/ui/footer'
import FilterPanel from 'components/ui/common/filter'
import moment from 'moment'

const Dashboard = () => {
  const { t } = useTranslation(['translation', 'specialty'])

	const [fromDate, setFromDateFilter] = useState(moment.utc().startOf('month').toDate())
	const [toDate, setToDateFilter]     = useState(new Date())
	const [categoryFilter, setCategoryFilter] = useState('')

	const onFilterChange = (source, value) => {
		switch(source) {
			case 'fromDate':
				setFromDateFilter(moment.utc(value).toDate())
				break
			case 'toDate':
				setToDateFilter(moment.utc(value).toDate())
				break
			case 'category':
				setCategoryFilter(value)
        break
      default:
		}
	}

	return (
		<>
			<Container fluid>
				<h2>{ t('translation:GENERIC:DASHBOARD') }</h2>

				<FilterPanel
					categories = { t('specialty:LIST') }
					onChange   = { onFilterChange }
					fromDate   = { moment.utc(fromDate).format('YYYY-MM-DD') }
					toDate     = { moment.utc(toDate).format('YYYY-MM-DD') }
					category   = { categoryFilter }
					className  = 'filter-row'

          dateRageLabel   = { t('translation:GENERIC:FILTER_FROM_REGISTRATION_DATE') }
          dateRageLabelTo = { t('translation:GENERIC:FILTER_TO_DATE') }
          categoryLabel   = { t('translation:GENERIC:FILTER_CATEGORY') } />

				<DashboardCharts
					fromDate = { fromDate }
					toDate   = { toDate }
          category = { categoryFilter }
        />

				<ActiveConsultations category = { categoryFilter } />
			</Container>
			<Footer />
		</>
	)
}

export default Dashboard
