import React, {useState} from 'react'
import { Row, Col, Container, Dropdown, Card, Table } from 'react-bootstrap'
import { IoEllipsisHorizontalSharp, IoBan } from 'react-icons/io5'
import { IoIosStats } from 'react-icons/io'
import { AiOutlineUser } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getDoctorsList } from 'actions/doctor'
import Pagination from 'components/ui/common/paginate'
import FilterPanel from 'components/ui/common/filter'
import Dot from 'components/ui/common/dot'
import Footer from 'components/ui/footer'
import DoctorAvatar from 'components/ui/common/avatar'
import Loader from 'components/ui/common/loader'
import Specialty from 'components/ui/common/specialty'
import NoData from 'components/ui/common/noData'

const STATUS_DOT_SIZE = 13

const DoctorRow = ({ _id, uid, data, created, isOnline, reports }) => {

	const history = useHistory()
	const { t }   = useTranslation(['translation'])

	const LABELS        = t('translation:GENERIC')
	const FORMATTING    = t('translation:FORMATTING')

	const registeredOn       = moment.utc(created).format(FORMATTING['DATE_FORMAT'])
	const registeredOnDescr  = moment.utc(created).format(FORMATTING['DATETIME_FORMAT_DESC'])

  const goToProfile = () => history.push(`/doctor-profile/${_id}`)
  const goToStats = () => history.push(`/doctor-stats/${btoa(`${_id}`)}`)

	return <tr onClick = { goToProfile }>
		<td>
			<DoctorAvatar
				avatar = { data.profile.avatar.url }
				gender = { data.profile.gender }
				name   = { `${data.profile.firstName} ${data.profile.lastName}` }
			/>
		</td>
		<td className='doctor-specialty-list'>
			{
				data.profile.speciality.map((s, idx) => <Specialty key={`dli-sp-${_id.substr(-5,5)}-${idx}`} {...s} />)
			}
		</td>
		<td title = { registeredOnDescr }>{ registeredOn }</td>
		<td>
			<Dot
				className = 'margin-right-5'
				size      = { STATUS_DOT_SIZE }
				color     = { isOnline ? 'green' : 'orange' } />{' '}
				{ LABELS[isOnline ? 'ONLINE' : 'OFFLINE'] }
		</td>
		<td>{ reports }</td>
		<td>-</td>
		<td onClick={evt => evt.stopPropagation()}>
			<Dropdown>
				<Dropdown.Toggle id = { `${_id}-menu` } bsPrefix = '-' as = 'span'>
					<IoEllipsisHorizontalSharp />
				</Dropdown.Toggle>

				<Dropdown.Menu>
        <Dropdown.Item onClick = { goToStats }>
						<IoIosStats /> { LABELS['STATISTICS'] }
					</Dropdown.Item>
					<Dropdown.Item onClick = { goToProfile }>
						<AiOutlineUser /> { LABELS['PROFILE'] }
					</Dropdown.Item>
					<Dropdown.Item disabled>
						<IoBan /> { LABELS['DEACTIVATE'] }
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</td>
	</tr>
}

const DoctorsTable = ({ items }) => {

	const { t }  = useTranslation(['translation'])
	const LABELS = t('translation:GENERIC')

	return <Table hover>
		<thead>
			<tr>
				<th>{ LABELS['DOCTOR'] }</th>
				<th>{ LABELS['SPECIALITY'] }</th>
				<th>{ LABELS['REGISTERED'] }</th>
				<th>{ LABELS['STATUS'] }</th>
				<th>{ LABELS['APPLICATIONS'] }</th>
				<th>{ LABELS['WORKED'] }</th>
				<th>&nbsp;</th>
			</tr>
		</thead>

		<tbody>
			{
				items.map((el, idx) => <DoctorRow key = {`dli-${el._id.substr(-10,10)}`} {...el} />)
			}
		</tbody>
	</Table>
}

const DoctorList = () => {
	const [currentPage, setCurrentPage]   = useState(1)
	const [itemsPerPage, setItemsPerPage] = useState(10)

	const changePage = ({ currentPage }) => {
    if(currentPage > 0) {
      setCurrentPage(currentPage)
    }
  }

	const [fromDateFilter, setFromDateFilter] = useState(moment.utc().startOf('year'))
	const [toDateFilter,   setToDateFilter]   = useState(new Date())
	const [categoryFilter, setCategoryFilter] = useState('')

	const { t }          = useTranslation(['translation', 'specialty'])
	const LABELS         = t('translation:GENERIC')
	const SPECIALTY_LIST = t('specialty:LIST')

	const dispatch = useDispatch()

	const doctorData = useSelector(s => s.doctor.doctors)

	if (!doctorData.isLoaded && !doctorData.isFetching) {
		dispatch(getDoctorsList())
	}

	let body = null
  let filteredData = doctorData?.data

	if (!doctorData.isFetching) {
		const indexOfLastEntry  = currentPage * itemsPerPage
    const indexOfFirstEntry = indexOfLastEntry - itemsPerPage

    if (fromDateFilter) {
      filteredData = filteredData.filter(({ created }) => moment.utc(created).startOf('day').isSameOrAfter(fromDateFilter) )
    }

    if (toDateFilter) {
      filteredData = filteredData.filter(({ created }) => moment.utc(created).startOf('day').isSameOrBefore(toDateFilter) )
    }

		if (categoryFilter !== '') {
			filteredData = filteredData.filter(e => e.data.profile.speciality.map(e => e.type).includes(categoryFilter))
		}
		const dataSubSet = filteredData.slice(indexOfFirstEntry, indexOfLastEntry)
		body = <DoctorsTable items = { dataSubSet } />
  }

	const onFilterChange = (source, value) => {
		switch(source) {
			case 'fromDate':
        setFromDateFilter(moment.utc(value).toDate())
        setCurrentPage(1)
				break
			case 'toDate':
				setToDateFilter(moment.utc(value).toDate())
        setCurrentPage(1)
				break
			case 'category':
				setCategoryFilter(value)
        setCurrentPage(1)
        break
      default:
		}
  }

  return <>
		<Container fluid>
			<Card className = 'plain-header'>
				<Card.Header>
					<Row>
						<Col md = { 10 }>
							<h5>{ LABELS['DOCTOR_LIST'] }</h5>
						</Col>
						</Row>
						<Row className = 'filter-row'>
							<FilterPanel
								categories = { SPECIALTY_LIST }
								onChange = { onFilterChange }
								fromDate = { moment.utc(fromDateFilter).format('YYYY-MM-DD') }
								toDate   = { moment.utc(toDateFilter).format('YYYY-MM-DD') }
								category = { categoryFilter }
							/>
						</Row>
				</Card.Header>

				<Card.Body>
          <Loader {...doctorData}>
            <NoData data = { filteredData } wrapper = { false }>{ body }</NoData>
          </Loader>
        </Card.Body>

				<Card.Footer className='text-center'>
					<div className='d-inline-block'>
            <Pagination
              currentPage    = { currentPage }
              totalRecords   = { filteredData?.length || 0 }
              pageLimit      = { itemsPerPage }
              pageNeighbours = { 1 }
              onPageChanged  = { changePage } />
          </div>
				</Card.Footer>
			</Card>
		</Container>
		<Footer />
	</>
}

export default DoctorList