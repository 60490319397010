import React, { Component } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { Card, Col, Row, Container, Button, Spinner, Alert } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

import { getDoctorProfile, deactivateDoctor } from 'actions/doctorProfile'
import { getDoctorStats } from 'actions/doctorStats'

import PersonalInfo from './personalInfo.jsx'
import Footer from 'components/ui/footer'
import Loader from 'components/ui/common/loader'
import BackIcon from 'assets/images/icons/back-icon.svg'
import BarChart from 'components/ui/charts/Bar'
import NoData from 'components/ui/common/noData'
import { convertTimeToDecimal } from 'utils/'

momentDurationFormatSetup(moment)

const ChartLabel = ({value, label, suffix}) => {
  return <div className='chart-title'>
    <span className='main-number'>{value}</span> {suffix}
    <p>{label}</p>
  </div>
}

const BackPage = ({ label }) => {
  const history = useHistory()
  return <div className = 'col-6' onClick = { () => history.goBack() }>
    <img alt = '' src = { BackIcon } className = 'back' />
    { label }
  </div>
}

class DoctorStats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDenyModalOpen    : false,
      isApproveModalOpen : false
    }
    const toDate   = moment.utc().format('YYYY-MM-DD')
    const fromDate = moment.utc().startOf('day').subtract(30, 'days').format('YYYY-MM-DD')

    this.props.dispatch(getDoctorProfile(atob(this.props.match.params.id)))
    this.props.dispatch(getDoctorStats(atob(this.props.match.params.id), fromDate, toDate))
  }

  render() {
    const { doctorStats, doctorProfile, t } = this.props
    const DUR_TIME_FORMAT = t('translation:FORMATTING:DURATION_TIME_FORMAT_SHORT')

    const profile = doctorProfile.data?.profile
    const uid = doctorProfile.uid
    const stats = doctorStats.data

    let avgConsultationsByDoctor = 0
    let consultingsPerDay = []
    let totalConsultations = 0
    const totalWorkTime = moment.duration(0)
    let workHoursMap = []
    let totalWorkHoursMap = new Array(doctorStats.data?.length).fill(0)

    if(doctorStats.data) {
      totalConsultations = doctorStats.data.reduce((acc, el) => acc + el.patientsCount, 0)
      avgConsultationsByDoctor = totalConsultations / doctorStats.data.length
      consultingsPerDay = doctorStats.data.map(el => ({
        x         : moment.utc(el.dateReference).toDate().getTime(),
        formatted : moment.utc(el.dateReference).format(t('translation:FORMATTING:DATE_FORMAT')),
        y         : el.patientsCount
      }))

      doctorStats.data.forEach(({ dateReference, workLog }, idx) => {
        let startTime = null
        workLog
          .forEach(el => {
            if (el.type === 'started-work') {
              startTime = el.date
            } else if (startTime && el.type === 'ended-work') {
              const timeDiff = moment.utc(el.date).toDate().getTime() - moment.utc(startTime).toDate().getTime()
              totalWorkHoursMap[idx] += timeDiff
              totalWorkTime.add(timeDiff / 1000, 'seconds')

              const endTimeN   = convertTimeToDecimal(moment.utc(el.date).format('HH:mm:ss'))
              const startTimeN = convertTimeToDecimal(moment.utc(startTime).format('HH:mm:ss'))

              workHoursMap.push({
                dateFormatted: moment.utc(dateReference).format('DD-MM-YYYY'),
                x: moment.utc(dateReference).toDate().getTime(),
                y: [ startTimeN, endTimeN ],
                formatted: moment.utc(timeDiff).format('HH:mm'),
                idx,
                start: moment.utc(startTime).format('HH:mm:ss'),
                end: moment.utc(el.date).format('HH:mm:ss')
              })

              startTime = null
            }
          })
      })

    }

    return <>
      <Container fluid>
        <Row>
          <Col className='margin-bottom-25' sm = { 12 } md = { 12 } >
            <Card className = 'plain-header'>
              <Card.Header>
                <Row>
                  <Loader isLoading = { !stats }>
                    <BackPage label = { t('translation:GENERIC:BACK') } />
                    <h5 className='col-6 profile-header'>
                      {/* { t('translation:GENERIC:DOCTOR_PREFIX') } {profile?.firstName} {profile?.lastName} */}
                    </h5>
                  </Loader>
                </Row>
              </Card.Header>

              <Loader isLoading = { !profile }>
                <PersonalInfo className = 'personal-info-header' { ...profile }/>
              </Loader>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col className='margin-bottom-25' sm = { 1 } md = { 8 } >
            <Card style={{paddingLeft: '-5px'}}>
              <Card.Body style={{paddingBottom: 0}}>
                <ChartLabel
                  value  = { totalWorkTime.format(DUR_TIME_FORMAT) }
                  label  = { t('translation:GENERIC:WORK_TIME_LOG') }
                  suffix = { `${t('translation:GENERIC:OF_WORK_IN')} ${doctorStats.data?.length} ${t('translation:GENERIC:DAYS')}` }
                />
              </Card.Body>
              <Loader {...doctorStats}>
                <NoData data = { doctorStats.data }>
                  <BarChart
                    type      = 'rangeBar'
                    height    = { 180 }
                    series    = { [{
                      name : `${t('translation:GENERIC:TOTAL')} : ${totalWorkTime.format(DUR_TIME_FORMAT)}`,
                      data : workHoursMap
                    }] }
                    xAxis = {{ type: 'datetime' }}
                    yaxisFormatter = { v => `${v/10000}` }

                    tooltip = {{
                      custom: ({ series, seriesIndex, dataPointIndex, w }) => `
                        <div class="chart-tooltip-container" style="font-size: 12px;">
                          <div class="apexcharts-tooltip-title" style="font-size: 12px;">
                            ${workHoursMap[dataPointIndex].dateFormatted}
                          </div>
                          <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                            Period:&nbsp;<strong>${workHoursMap[dataPointIndex].start} - ${workHoursMap[dataPointIndex].end}</strong>
                          </div>
                          <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: rgba(65, 173, 68, 0.85);"></span>
                            Day total:&nbsp;<strong>${moment.utc(totalWorkHoursMap[workHoursMap[dataPointIndex].idx]).format('HH:mm')}</strong>
                          </div>
                          <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: rgba(65, 173, 68, 0.85);"></span>
                            Current Period:&nbsp;<strong>${workHoursMap[dataPointIndex].formatted}</strong>
                          </div>
                        </div>`
                    }}
                  />
                </NoData>
              </Loader>
            </Card>
          </Col>
          <Col className='margin-bottom-25' sm = { 1 } md = { 4 } >
            <Card style={{paddingLeft: '-5px'}}>
              <Card.Body style={{paddingBottom: 0}}>
                <ChartLabel
                  value  = { totalConsultations }
                  label  = { t('translation:GENERIC:DOCTOR_CONSULTATIONS') }
                  suffix = { `${t('translation:GENERIC:CONSULTATIONS_IN')} ${consultingsPerDay.length} ${t('translation:GENERIC:DAYS')}` }
                />
              </Card.Body>
              <Loader {...doctorStats}>
                <NoData data = { doctorStats.data }>
                  <BarChart
                    height    = { 180 }
                    series    = { [{
                      name : t('translation:GENERIC:PATIENTS_BY_DOC'),
                      data : consultingsPerDay
                    }] }
                    xAxis = {{ type: 'datetime' }}
                    annotations = {{
                      yaxis: [
                        {
                          y: avgConsultationsByDoctor,
                          borderColor: '#00E396',
                          label: {
                            borderColor: '#00E396',
                            style: {
                              color: '#15315291',
                              background: '#00e39691'
                            },
                            text: `${avgConsultationsByDoctor.toFixed(1)} ${t('translation:GENERIC:AVERAGE')}`
                          }
                        }
                      ]
                    }}
                    tooltip = {{
                      custom: ({ series, seriesIndex, dataPointIndex, w }) => `
                        <div class="chart-tooltip-container" style="font-size: 12px;">
                          <div class="apexcharts-tooltip-title" style="font-size: 12px;">
                            ${consultingsPerDay[dataPointIndex].formatted}
                          </div>
                          <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: rgba(65, 173, 68, 0.85);"></span>
                            ${t('translation:GENERIC:TOTAL_CONSULTATIONS')}:&nbsp;<strong>${series[seriesIndex][dataPointIndex]}</strong>
                          </div>
                        </div>`
                    }}
                  />
                </NoData>
              </Loader>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    </>
  }
}

const mapStateToProps = ({ doctorStats, doctorProfile }) => ({ doctorStats, doctorProfile })

export default connect(mapStateToProps)(withTranslation()(DoctorStats))
