const defaultState = {
  isFetching : false,
  data       : null,
  error      : null
}

export const ACTION_TYPES = {
  GET_DOCTORS_STATS_REQ  : 'GET_DOCTORS_STATS_REQ',
  GET_DOCTORS_STATS_SUCC : 'GET_DOCTORS_STATS_SUCC',
  GET_DOCTORS_STATS_FAIL : 'GET_DOCTORS_STATS_FAIL'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {

    case ACTION_TYPES.GET_DOCTORS_STATS_REQ:
      return {
        ...state,
        isFetching : true,
        data       : null,
        error      : action.error
      }
    case ACTION_TYPES.GET_DOCTORS_STATS_SUCC:
      return {
        ...state,
        isFetching : false,
        data       : action.data,
        error      : null
      }
    case ACTION_TYPES.GET_DOCTORS_STATS_FAIL:
      return {
        ...state,
        isFetching : false,
        isLoaded   : false,
        data       : null,
        error      : action.error
      }
    default:
      return state
  }
}