const defaultState = {
  isFetching : false,
  data       : null,
  error      : null,
  uid        : null,

  activation   : {
    isFetching : false,
    data       : null,
    error      : null
  },
  deactivation : {
    isFetching : false,
    data       : null,
    error      : null
  }
}

export const ACTION_TYPES = {
  GET_DOCTOR_PROFILE_REQ  : 'GET_DOCTOR_PROFILE_REQ',
  GET_DOCTOR_PROFILE_SUCC : 'GET_DOCTOR_PROFILE_SUCC',
  GET_DOCTOR_PROFILE_FAIL : 'GET_DOCTOR_PROFILE_FAIL',

  ACTIVATE_DOCTOR_REQ  : 'ACTIVATE_DOCTOR_REQ',
  ACTIVATE_DOCTOR_SUCC : 'ACTIVATE_DOCTOR_SUCC',
  ACTIVATE_DOCTOR_FAIL : 'ACTIVATE_DOCTOR_FAIL',

  DEACTIVATE_DOCTOR_REQ  : 'DEACTIVATE_DOCTOR_REQ',
  DEACTIVATE_DOCTOR_SUCC : 'DEACTIVATE_DOCTOR_SUCC',
  DEACTIVATE_DOCTOR_FAIL : 'DEACTIVATE_DOCTOR_FAIL'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.ACTIVATE_DOCTOR_REQ:
      return {
        ...state,
        activation   : {
          isFetching : true,
          data       : null,
          error      : null
        }
      }
    case ACTION_TYPES.ACTIVATE_DOCTOR_SUCC: {
      const { profile } = state.data
      profile.activation.activated = true
      return {
        ...state,
        data: {
          ...state.data,
          profile
        },
        activation   : {
          isFetching : false,
          data       : action.data,
          error      : null
        }
      }
    }
    case ACTION_TYPES.ACTIVATE_DOCTOR_FAIL:
      return {
        ...state,
        activation   : {
          isFetching : false,
          data       : null,
          error      : action.error
        }
      }
      case ACTION_TYPES.DEACTIVATE_DOCTOR_REQ:
        return {
          ...state,
          deactivation   : {
            isFetching : true,
            data       : null,
            error      : null
          }
        }
      case ACTION_TYPES.DEACTIVATE_DOCTOR_SUCC: {
        const { profile } = state.data
        profile.activation.activated = false
        return {
          ...state,
          data: {
            ...state.data,
            profile
          },
          deactivation   : {
            isFetching : false,
            data       : action.data,
            error      : null
          }
        }
      }
      case ACTION_TYPES.DEACTIVATE_DOCTOR_FAIL:
        return {
          ...state,
          deactivation   : {
            isFetching : false,
            data       : null,
            error      : action.error
          }
        }

    case ACTION_TYPES.GET_DOCTOR_PROFILE_REQ:
      return {
        ...state,
        isFetching : true,
        data       : null,
        error      : action.error
      }
    case ACTION_TYPES.GET_DOCTOR_PROFILE_SUCC:
      return {
        ...state,
        isFetching : false,
        data       : action.data,
        uid        : action.uid,
        error      : null
      }
    case ACTION_TYPES.GET_DOCTOR_PROFILE_FAIL:
      return {
        ...state,
        isFetching : false,
        isLoaded   : false,
        data       : null,
        error      : action.error
      }
    default:
      return state
  }
}