import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class Pagination extends Component {

  constructor(props) {
    super(props)
    const { totalRecords = null, pageLimit = 10, pageNeighbours = 0 } = props

    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0

    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours = typeof pageNeighbours === 'number'
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  componentDidMount() {
    this.gotoPage(this.props.currentPage)
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props
    const currentPage = Math.max(0, Math.min(page, this.totalPages))
    const paginationData = {
      currentPage,
      totalPages   : this.totalPages,
      pageLimit    : this.pageLimit,
      totalRecords : this.totalRecords
    }
    onPageChanged(paginationData)
  }

  handleClick = page => evt => {
    evt.preventDefault()
    if (this.props.currentPage !== page) {
      this.gotoPage(page)
    }
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    this.gotoPage(this.props.currentPage - (this.pageNeighbours * 2) - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.props.currentPage + (this.pageNeighbours * 2) + 1)
  }

  fetchPageNumbers = () => {
    const totalPages     = this.totalPages
    const currentPage    = this.props.currentPage
    const pageNeighbours = this.pageNeighbours
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (this.pageNeighbours * 2) + 3
    const totalBlocks  = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage   = Math.min(totalPages - 1, currentPage + pageNeighbours)
      let pages       = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = (totalPages - endPage) > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    this.totalRecords = typeof this.props.totalRecords === 'number' ? this.props.totalRecords : 0
    this.totalPages   = Math.ceil(this.props.totalRecords / this.props.pageLimit)

    if (!this.props.totalRecords) return null
    // if (!this.props.totalRecords || this.totalPages === 1) return null

    const { currentPage } = this.props
    const pages   = this.fetchPageNumbers()
    const pageObj = {
      currentPage,
      handleMoveLeft  : this.handleMoveLeft,
      handleMoveRight : this.handleMoveRight,
      handleClick     : this.handleClick
    }
    return (
      <Fragment>
        <nav aria-label='Pagination'>
          <ul className='pagination'>
            {
              pages.map((page, index) =>
                <PageItem
                  key   = {`pagination-${index}`}
                  page  = { page }
                  index = { index }
                  { ...pageObj }
                />
              )
            }
          </ul>
        </nav>
      </Fragment>
    )
  }
}

const PageItem = ({ currentPage, page, index, handleMoveLeft, handleMoveRight, handleClick }) => {
  if (page === LEFT_PAGE)
    return <li key={index} className='page-item'>
      <span className='page-link' aria-label='Previous' onClick={handleMoveLeft}>
        <span aria-hidden='true'><FaAngleDoubleLeft /></span>
        <span className='sr-only'>Previous</span>
      </span>
    </li>

  if (page === RIGHT_PAGE)
    return <li key={index} className='page-item'>
      <span className='page-link' aria-label='Next' onClick={handleMoveRight}>
        <span aria-hidden='true'><FaAngleDoubleRight /></span>
        <span className='sr-only'>Next</span>
      </span>
    </li>

  return <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
    <span className='page-link' onClick={handleClick(page)}>{page}</span>
  </li>
}

Pagination.propTypes = {
  totalRecords   : PropTypes.number.isRequired,
  pageLimit      : PropTypes.number,
  pageNeighbours : PropTypes.number,
  onPageChanged  : PropTypes.func,
  currentPage    : PropTypes.number
}

export default Pagination