import React, { Component } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { Card, Col, Row, Container, Table } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

import { getDoctorsStats } from 'actions/doctorStats'

import Footer from 'components/ui/footer'
import Loader from 'components/ui/common/loader'
import NoData from 'components/ui/common/noData'
import FilterPanel from 'components/ui/common/filter'
import DoctorAvatar from 'components/ui/common/avatar'

momentDurationFormatSetup(moment)

const STARTED_WORK_EVT = 'started-work'
const ENDED_WORK_EVT   = 'ended-work'

const PATIENT_RECEIV_STARTED_EVT = 'created'
const PATIENT_RECEIV_ENDED_EVT   = 'report-sent'

class DoctorsStats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryFilter : '',
      toDate         : moment.utc().startOf('day').toDate(),
      fromDate       : moment.utc().startOf('day').subtract(6, 'days').toDate()
    }

    this.props.dispatch(getDoctorsStats(this.state.fromDate, this.state.toDate))
    this.onFilterChange = this.onFilterChange.bind(this)
  }

  onFilterChange(source, value) {

    switch(source) {
			case 'fromDate':
        const fromDate = moment.utc(value).toDate()
        this.setState({
          ...this.state,
          fromDate
        }, () => this.props.dispatch(getDoctorsStats(fromDate, this.state.toDate)))
				break
			case 'toDate':
        const toDate = moment.utc(value).toDate()
        this.setState({
          ...this.state,
          toDate
        }, () => this.props.dispatch(getDoctorsStats(this.state.fromDate, toDate)))
				break
			case 'category':
        this.setState({
          ...this.state,
          categoryFilter: value
        })
        break
      default:
		}
  }

  render() {
    const { doctorsStats, t } = this.props

    const TIME_FORMAT      = t('translation:FORMATTING:TIME_FORMAT')
    const DUR_TIME_FORMAT  = t('translation:FORMATTING:DURATION_TIME_FORMAT_SHORT')
    const DATE_FORMAT      = t('translation:FORMATTING:DATE_FORMAT')
    const DATE_FORMAT_DESC = t('translation:FORMATTING:DATE_FORMAT_DESC')

    console.log("t('specialty:LIST')", t('specialty:LIST'))

    const stats = doctorsStats.data || []
    let doctors = []

    if(stats) {
      stats.reduce((acc, { _id, dateReference, noShowCount, patientsCount, receiving, workLog, doctor}) => {
        if(!acc[_id.doctor]) {
          acc[_id.doctor] = {
            doctor,
            noShowCount: 0,
            patientsCount: 0,
            logs: []
          }
          doctors.push(acc[_id.doctor])
        }
        acc[_id.doctor].noShowCount += noShowCount
        acc[_id.doctor].patientsCount += patientsCount
        acc[_id.doctor].logs.push({
          noShowCount,
          patientsCount,
          receiving,
          workLog,
          dateReference
        })
        return acc
      }, {})
    }

    doctors = doctors.map((el) => {
      const totalPeriodWorkTime = moment.duration(0)
      const totalPeriodCallTime = moment.duration(0)

      const logs = el.logs.map(({ workLog, receiving, dateReference, noShowCount, patientsCount }) => {
        let startTime = null
        let totalDayWorkTime = 0
        let totalDayCallTime = 0

        workLog
          .forEach(el => {
            if (el.type === STARTED_WORK_EVT) {
              startTime = el.date
            } else if (startTime && el.type === ENDED_WORK_EVT) {
              const timeDiff = moment.utc(el.date).toDate().getTime() - moment.utc(startTime).toDate().getTime()
              totalPeriodWorkTime.add(timeDiff / 1000, 'seconds')
              totalDayWorkTime += timeDiff
              startTime = null
            }
          })
          receiving
            .forEach(el => {
              if (el.type === PATIENT_RECEIV_STARTED_EVT) {
                startTime = el.date
              } else if (startTime && el.type === PATIENT_RECEIV_ENDED_EVT) {
                const timeDiff = moment.utc(el.date).toDate().getTime() - moment.utc(startTime).toDate().getTime()
                totalDayCallTime += timeDiff
                totalPeriodCallTime.add(timeDiff / 1000, 'seconds')
                startTime = null
              }
            })

        return {
          dateReference,
          noShowCount,
          patientsCount,
          totalDayWorkTime,
          totalDayCallTime
        }
      })
      return {
        ...el,
        totalPeriodCallTime,
        totalPeriodWorkTime,
        logs
      }
    })

    return <Container fluid>
      <Row>
        <Col>
          <FilterPanel
            categories = { [] } //t('specialty:LIST')
            onChange   = { this.onFilterChange }
            fromDate   = { moment.utc(this.state.fromDate).format('YYYY-MM-DD') }
            toDate     = { moment.utc(this.state.toDate).format('YYYY-MM-DD') }
            category   = { this.state.categoryFilter }
            className  = 'filter-row'

            dateRageLabel   = { t('translation:GENERIC:FILTER_FROM_DATE') }
            dateRageLabelTo = { t('translation:GENERIC:FILTER_TO_DATE') }
          />
        </Col>
      </Row>
      <Row>
        <Col className='margin-bottom-25 profile-header' sm = { 12 } md = { 12 } >
          { t('translation:GENERIC:DOCTORS_STATS') }
        </Col>
      </Row>
      <Row>
        <Col>
          <Loader data = { doctorsStats }>
            {doctors.map(el => <Card>
              <Card.Header>
                <div className='inline-block'>
                  <DoctorAvatar
                    avatar = { el.doctor.avatar.url }
                    gender = { el.doctor.gender }
                  />
                </div>
						    <div className='inline-block margin-left-25 v-center'>
                  {t('translation:GENERIC:DOCTOR_PREFIX')} <b>{el.doctor.firstName} {el.doctor.lastName}</b>
                  <div className='font-point-8'>
                    { t('translation:GENERIC:WORK_START_DATE') } : { moment.utc(el.doctor.activation.date).format(DATE_FORMAT_DESC) }
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Row className = 'margin-bottom-25'>
                  <Col md={6}>
                    <b>{ t('translation:GENERIC:TOTAL_DAYS_WORKED') }</b>: {el.logs.length}
                    <div><b>{ t('translation:GENERIC:TOTAL_HOURS_WORKED') }: </b>
                    {el.totalPeriodWorkTime.format(DUR_TIME_FORMAT)}
                    </div>
                    <div>
                      <b>{ t('translation:GENERIC:TOTAL_CALL_DURATION') }</b>: { el.totalPeriodCallTime.format(DUR_TIME_FORMAT)}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <b>{ t('translation:GENERIC:TOTAL_PATIENTS') }</b>: { el.patientsCount }
                    </div>
                    <div>
                      <b>{ t('translation:GENERIC:TOTAL_NO_SHOW') }</b>: { el.noShowCount }
                    </div>
                  </Col>
                </Row>
                <Table hover bordered = {false}>
                  <thead>
                    <tr>
                      <th>{ t('translation:GENERIC:DATE') }</th>
                      <th>{ t('translation:GENERIC:HOURS_WORKED') }</th>
                      <th>{ t('translation:GENERIC:PATIENTS_COUNT') }</th>
                      <th>{ t('translation:GENERIC:NO_SHOW_COUNT') }</th>
                      <th>{ t('translation:GENERIC:CALL_DURATION') }</th>
                    </tr>
                  </thead>
                  <tbody>
                    {el.logs.map(log => <tr>
                      <td>{moment.utc(log.dateReference).format(DATE_FORMAT)}</td>
                      <td>{moment.utc(log.totalDayWorkTime).format(TIME_FORMAT)}</td>
                      <td>{log.patientsCount}</td>
                      <td>{log.noShowCount}</td>
                      <td>{moment.utc(log.totalDayCallTime).format(TIME_FORMAT)}</td>
                    </tr>)}
                  </tbody>
                </Table>
              </Card.Body>

                {/* <Table bordered>
              <thead>
                <tr>
                  <th>{ t('translation:GENERIC:DOCTOR') }</th>
                  <th>{ t('translation:GENERIC:TOTAL_DAYS_WORKED') }</th>
                  <th>{ t('translation:GENERIC:TOTAL_HOURS_WORKED') }</th>
                  <th>{ t('translation:GENERIC:TOTAL_PATIENTS') }</th>
                  <th>{ t('translation:GENERIC:TOTAL_NO_SHOW') }</th>
                  <th>{ t('translation:GENERIC:TOTAL_CALL_DURATION') }</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>{el.doctor.firstName} {el.doctor.lastName}</td>
                    <td>{el.logs.length}</td>
                    <td>{moment.utc(el.totalPeriodWorkTime).format(TIME_FORMAT)}</td>
                    <td>{el.patientsCount}</td>
                    <td>{el.noShowCount}</td>
                    <td>{moment.utc(el.totalPeriodCallTime).format(TIME_FORMAT)}</td>
                  </tr>
                  <tr>
                    <td colSpan = { 6 }>
                      <Table hover bordered = {false}>
                        <thead>
                          <tr>
                            <th>{ t('translation:GENERIC:DATE') }</th>
                            <th>{ t('translation:GENERIC:HOURS_WORKED') }</th>
                            <th>{ t('translation:GENERIC:PATIENTS_COUNT') }</th>
                            <th>{ t('translation:GENERIC:NO_SHOW_COUNT') }</th>
                            <th>{ t('translation:GENERIC:CALL_DURATION') }</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el.logs.map(log => <tr>
                            <td>{moment.utc(log.dateReference).format(t('translation:FORMATTING:DATE_FORMAT'))}</td>
                            <td>{moment.utc(log.totalDayWorkTime).format(TIME_FORMAT)}</td>
                            <td>{log.patientsCount}</td>
                            <td>{log.noShowCount}</td>
                            <td>{moment.utc(log.totalDayCallTime).format(TIME_FORMAT)}</td>
                          </tr>)}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
            </Table> */}
            </Card>
            )}
          </Loader>
        </Col>
      </Row>

      <Footer />

    </Container>
  }
}

const mapStateToProps = ({ doctorsStats }) => ({ doctorsStats })

export default connect(mapStateToProps)(withTranslation('translation','specialty')(DoctorsStats))
