import axios from 'axios'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import reducers from 'reducers/'
import { config } from 'utils/config'

// axios.defaults.withCredentials = true
// axios.defaults.baseURL = config.server.host
// axios.defaults.timeout = 30000

export default createStore(combineReducers({
  ...reducers
}), applyMiddleware(thunkMiddleware.withExtraArgument({
  api: axios.create({
    withCredentials : true,
    baseURL         : config.server.host,
    timeout         : 30000
  })
})))