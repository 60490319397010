import { ACTION_TYPES } from 'reducers/doctorProfile'
import { getNotApprovedDoctors, getDoctorsList } from './doctor'

export const activateDoctor = doctorId => async (dispatch, getStore, { api }) => {

  dispatch({ type: ACTION_TYPES.ACTIVATE_DOCTOR_REQ })
  const { auth: { isAuthorized, token } } = getStore()

  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctor/approve',
        method  : 'post',
        headers : {
          Authorization: `Bearer ${token}`
        },
        data: {
          doctorId
        }
      })

      dispatch(getNotApprovedDoctors())
      dispatch(getDoctorsList())
      dispatch({
        type : ACTION_TYPES.ACTIVATE_DOCTOR_SUCC,
        data : resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.ACTIVATE_DOCTOR_FAIL,
        error
      })
    }
  }
}

export const deactivateDoctor = (doctorId, reason) => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.DEACTIVATE_DOCTOR_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctor/reject',
        method  : 'post',
        headers : {
          Authorization: `Bearer ${token}`
        },
        data: {
          doctorId,
          comments: reason
        }
      })

      dispatch(getNotApprovedDoctors())
      dispatch(getDoctorsList())
      dispatch({
        type: ACTION_TYPES.DEACTIVATE_DOCTOR_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.DEACTIVATE_DOCTOR_FAIL,
        error
      })
    }
  }
}

export const getDoctorProfile = doctorId => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.GET_DOCTOR_PROFILE_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctor',
        method  : 'post',
        headers : {
          Authorization: `Bearer ${token}`
        },
        data: {
          doctorId
        }
      })
      dispatch({
        type : ACTION_TYPES.GET_DOCTOR_PROFILE_SUCC,
        data : resp.data.data,
        uid  : resp.data.uid
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.GET_DOCTOR_PROFILE_FAIL,
        error
      })
    }
  }
}
