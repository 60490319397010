const defaultState = {
  list    : [],
  current : 'en'
}

export const ACTION_TYPES = {
  SET_LANGUAGE_LIST    : 'SET_LANGUAGES',
  SET_CURRENT_LANGUAGE : 'SET_LANGUAGE'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current: action.current
      }
    case ACTION_TYPES.SET_LANGUAGE_LIST:
      return {
        ...state,
        list: action.languages
      }
    default:
      return state
  }
}