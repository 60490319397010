import React from 'react'
import { Form, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const FilterPanel = ({
  onChange,
  category,
  fromDate,
  toDate,
  categories = [],
  dateRageLabel = '',
  dateRageLabelTo = '',
  categoryLabel = ''
}) => {
	const { t }  = useTranslation(['translation'])
  return <Form id = 'filter-component'>
    <Form.Row id = 'filter-form-row'>
      <Form.Group as = { Col } controlId = 'formRegistration' >
        <Form.Label id='registration-label'>{ dateRageLabel }</Form.Label>
        <Form.Control
          className = 'filter-input filter-by-date'
          type      = 'date'
          value     = { fromDate }
          onChange  = { evt => onChange('fromDate', evt.currentTarget.value) }

        />
      </Form.Group>

      <Col id = 'filter-date-to-label'>{ dateRageLabelTo }</Col>

      <Form.Group as={Col} controlId='formRegistration'>
        <Form.Label>&nbsp;</Form.Label>
        <Form.Control
          className = 'filter-input filter-by-date'
          type      = 'date'
          value     = { toDate }
          onChange  = { evt => onChange('toDate', evt.currentTarget.value) }
        />
      </Form.Group>

      { categories.length ?
        <Form.Group as={Col} controlId='formSpecialty' >
          <Form.Label>{ categoryLabel }</Form.Label>

          <Form.Control
            className = 'filter-input'
            as        = 'select'
            value     = { category }
            onChange  = { evt => onChange('category', evt.currentTarget.value )}
          >
            {
              categories.map((cat, idx) => <option key = { `cat-filter-${idx}` } value = {cat.value}>{cat.label}</option>)
            }
          </Form.Control>
        </Form.Group>
      : null }
    </Form.Row>
  </Form>
}

export default FilterPanel
