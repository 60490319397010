import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Row,
	Col,
  Card
} from 'react-bootstrap'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import BarChart from 'components/ui/charts/Bar'
import { getStats } from 'actions/stats'
import Loader from 'components/ui/common/loader'
import NoData from 'components/ui/common/noData'

const ChartLabel = ({value, label, suffix}) => {
  return <div className='chart-title'>
    <span className='main-number'>{value}</span> {suffix}
    <p>{label}</p>
  </div>
}

const filterHash = (fromDate, toDate) => fromDate.getTime() + toDate.getTime()
const hasChanges = (old, fromDate, toDate) => old !== filterHash(fromDate, toDate)
let filterChanged = null

const DashboardCharts = ({ fromDate, toDate, category }) => {
  const { t } = useTranslation(['translation', 'specialty'])

  toDate = moment.utc(toDate).startOf('day').toDate()

  const dispatch = useDispatch()
  const stats    = useSelector(s => s.stats)

  if ((!stats.isLoaded && !stats.isFetching) || (stats.isLoaded && hasChanges(filterChanged, fromDate, toDate))) {
    dispatch(getStats(fromDate, toDate))
    filterChanged = filterHash(fromDate, toDate)
    return null
  }

  if (!filterChanged) {
    filterChanged = filterHash(fromDate, toDate)
    return null
  }

  let statsData = stats.data || []
  if(category && category.trim() != '') {
    statsData = stats.data.filter(({ doctor: { speciality }}) => speciality.includes(category))
  }

  const dataSetDays = Array.from(statsData.reduce((set, { dateReference }) => set.add(dateReference), new Set())).sort()

  const doctorsMap = statsData.reduce((map, { dateReference }) => {
    const k = moment.utc(dateReference).format('YYDDMM')
    if(map[k]) {
      map[k] = map[k] + 1
    } else {
      map[k] = 1
    }
    return map
  }, {})

  const doctorsOnline = dataSetDays.map(day => ({
    x         : moment.utc(day).toDate().getTime(),
    formatted : moment.utc(day).format(t('translation:FORMATTING:DATE_FORMAT')),
    y         : doctorsMap[moment.utc(day).format('YYDDMM')]
  }))

  const weeks = Array.from(dataSetDays.reduce((set, date) => set.add(moment.utc(date).isoWeek()), new Set()))

  const consultationsByWeekMap = statsData.reduce((map, { dateReference, noShowCount, patientsCount }) => {
    const k = moment.utc(dateReference).isoWeek()
    if(map[k]) {
      map[k].patientsCount = map[k].patientsCount + patientsCount
      map[k].noShowCount = map[k].noShowCount + noShowCount
    } else {
      map[k] = { patientsCount, noShowCount }
    }
    return map
  }, {})

  const consultationsByWeek = weeks.map(week => ({
    week,
    formatted     : `${t('translation:GENERIC:WEEK')}: ${week}`,
    patientsCount : consultationsByWeekMap[week].patientsCount,
    noShowCount   : consultationsByWeekMap[week].noShowCount
  }))

  const overallConsultations = consultationsByWeek.reduce((acc, { patientsCount }) => (acc + patientsCount), 0)

  const consultationsByDoctorMap = statsData.reduce((map, { _id, doctor, patientsCount }) => {
    if(map[_id.doctor]) {
      map[_id.doctor].count = map[_id.doctor].count + patientsCount
    } else {
      map[_id.doctor] = {
        doctor,
        count: patientsCount
      }
    }
    return map
  }, {})

  const consultationsByDoctor = Object
    .values(consultationsByDoctorMap)
    .map(({ doctor, count }) => ({
      fullName : `${doctor.firstName || 'N/A'} ${doctor.lastName || 'N/A'}`,
      x        : doctor.lastName || 'N/A',
      y        : count
    }))
    .filter(({ y }) => y)

  let avgConsultationsByDoctor = 0
  if(consultationsByDoctor && consultationsByDoctor.length > 0) {
    avgConsultationsByDoctor = (consultationsByDoctor.reduce((acc, { y }) => (acc + y), 0) / consultationsByDoctor.length).toFixed(0)
  }

  let doctorsOnlineCount = 0
  if (doctorsOnline && doctorsOnline.length > 0) {
    doctorsOnlineCount = doctorsOnline[doctorsOnline.length - 1].y
  }

	return <Row>
    <Col className='margin-bottom-25' sm = { 1 } md = { 4 } >
      <Card style={{paddingLeft: '-5px'}}>
        <Card.Body style={{paddingBottom: 0}}>
          <ChartLabel
            value  = { doctorsOnlineCount }
            label  = { t('translation:GENERIC:DOCTORS_ONLINE') }
            suffix = { `${t('translation:GENERIC:OF')} ${consultationsByDoctor.length}` }
          />
        </Card.Body>
        <Loader {...stats}>
          <NoData data = { statsData }>
            <BarChart
              height    = { 160 }
              series    = { [{
                name : t('translation:GENERIC:DOCTORS_ONLINE'),
                data : doctorsOnline
              }] }
              xAxis = {{ type: 'datetime' }}
            />
          </NoData>
        </Loader>
      </Card>
    </Col>
    <Col className='margin-bottom-25' sm = { 1 } md = { 4 } >
      <Card style={{paddingLeft: '-5px'}}>
        <Card.Body style={{paddingBottom: 0}}>
          <ChartLabel
            value  = { overallConsultations }
            label  = { t('translation:GENERIC:DOCTOR_CONSULTATIONS') }
            suffix = { t('translation:GENERIC:OVERALL') }
          />
        </Card.Body>
        <Loader {...stats}>
          <NoData data = { statsData }>
            <BarChart
              height    = { 160 }
              series    = { [{
                name : t('translation:GENERIC:DOCTOR_CONSULTATIONS'),
                data : consultationsByWeek.map(el => ({ x: el.week, y: el.patientsCount }))
              },{
                name : t('translation:GENERIC:CANCELLED_CALLS'),
                data : consultationsByWeek.map(el => ({ x: el.week, y: el.noShowCount }))
              }] }
              xAxis = {{ tickAmount: 'dataPoints',  labels: { formatter: value => `${t('translation:GENERIC:WEEK')} ${value}`} }}
            />
          </NoData>
        </Loader>
      </Card>
    </Col>
    <Col className='margin-bottom-25' sm = { 1 } md = { 4 } >
      <Card>
        <Card.Body style={{paddingBottom: 0}}>
          <ChartLabel
            value  = { avgConsultationsByDoctor }
            label  = { t('translation:GENERIC:PATIENTS_BY_DOC') }
            suffix = { t('translation:GENERIC:AVERAGE') }
          />
        </Card.Body>
        <Loader {...stats}>
          <NoData data = { statsData }>
            <BarChart
              height    = { 160 }
              series    = { [{
                name : t('translation:GENERIC:PATIENTS_BY_DOC'),
                data : consultationsByDoctor.filter(({ y }) => y)
              }] }
              xAxis = {{ labels: { maxHeight: 50, hideOverlappingLabels: true } }}
              grid  = {{
                padding : {
                  bottom : -5
                }
              }}
              annotations = {{
                yaxis: [
                  {
                    y: avgConsultationsByDoctor,
                    borderColor: '#00E396',
                    label: {
                      borderColor: '#00E396',
                      style: {
                        color: '#15315291',
                        background: '#00e39691'
                      },
                      text: `${avgConsultationsByDoctor} ${t('translation:GENERIC:AVERAGE')}`
                    }
                  }
                ]
              }}
              tooltip = {{
                custom: ({ series, seriesIndex, dataPointIndex, w }) => `
                  <div class="chart-tooltip-container" style="font-size: 12px;">
                    <div class="apexcharts-tooltip-title" style="font-size: 12px;">
                      ${consultationsByDoctor[dataPointIndex].fullName}
                    </div>
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">
                      <span class="apexcharts-tooltip-marker" style="background-color: rgba(65, 173, 68, 0.85);"></span>
                      ${t('translation:GENERIC:TOTAL_CONSULTATIONS')}:&nbsp;<strong>${series[seriesIndex][dataPointIndex]}</strong>
                    </div>
                  </div>`
              }}
            />
          </NoData>
        </Loader>
      </Card>
    </Col>
  </Row>
}

export default DashboardCharts
