import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import _ from 'lodash'

const Component = ({
  series,
  height,
  xAxis,
  tooltip = {},
  grid = {},
  annotations = {},
  type = 'bar',
  yaxisFormatter = v => v
}) => {
  const axisStyle = {
    colors     : '#BDCBDB',
    fontSize   : '8px',
    fontFamily : 'Roboto',
    fontWeight : 400
  }
  const options = {
    grid: _.merge({
      borderColor     : '#BDCBDB',
      strokeDashArray : 3,
      padding         : {
        top   : -5,
        right : 30,
      }
    }, grid),
    annotations,
    chart: {
      // stacked: true,
      fontFamily: 'Roboto',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#41AD44', '#FF6B6B'],
    plotOptions: {
      bar: {
        columnWidth: '75%',
        distributed: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    tooltip: _.merge({
      followCursor: true
    }, tooltip),
    xaxis: _.merge({
      labels: {
        style: axisStyle
      }
    }, xAxis),
    yaxis: {
      labels: {
        offsetY: 4,
        style: axisStyle,
        formatter: yaxisFormatter
      }
    }
  }
  return <ReactApexChart
    options = { options }
    series  = { series }
    type    = { type }
    height  = { height }
  />
}

Component.propTypes = {
  series : PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  xAxis : PropTypes.object.isRequired,
  width : PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Component