import { useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RiShieldKeyholeFill } from 'react-icons/ri'

import SidebarMenu from 'components/ui/SidebarMenu/'
import Routes from 'components/routes'
import TopBarMenu from 'components/ui/TopBarMenu/'
import { setAuthToken } from 'actions/auth'
import Logo from 'assets/images/logo-vertical.svg'

function App() {

	const { getAccessTokenSilently, user } = useAuth0()
	const dispatch = useDispatch()
	const isAuthorized = useSelector(s => {
		return s.auth.isAuthorized
	})

	useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          // scope: 'openid'
				})
				dispatch(setAuthToken(token, user))
      } catch (e) {
        console.error(e)
      }
    })()
	}, [getAccessTokenSilently])

	if (!isAuthorized) {
		return null
	}
	return (
		<Router>
			<TopBarMenu />
			<Container fluid className = 'sidebar-layout'>
				<SidebarMenu />
				<Routes />
			</Container>
		</Router>
	)
}

const AuthSpinner = () => {
	const { t }  = useTranslation(['translation'])
	const LABELS = t('translation:GENERIC')
	return <Container>
		<div style = { { textAlign: 'center', paddingTop: '20%' } }>
			<img src = { Logo } style = { { marginBottom: '60px' } } />
			<div>
				<span className = 'alert alert-primary'>
					<RiShieldKeyholeFill />
					{' '}
					{LABELS['VALIDATING_AUTHENTICATION']}
				</span>
			</div>
	</div>
</Container>
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <AuthSpinner />,
})