import { ACTION_TYPES } from 'reducers/activeDoctors'

export const getActiveDoctors = () => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.DOCS_REQ })
  const { auth: { isAuthorized, token } } = getStore()
  if (isAuthorized && token) {
    try {
      const resp = await api.request({
        url     : '/api/admin/doctors/active',
        method  : 'get',
        headers : {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: ACTION_TYPES.DOCS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.DOCS_FAIL,
        error
      })
    }
  }
}