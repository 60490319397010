export const getLabelsHO = getTranslation => (ns = 'translation', cat = 'GENERIC') => {
  const { t } = getTranslation([ns])
  const MAP = t(`${ns}:${cat}`)
	return Object.assign(MAP, {
    t: key => MAP.hasOwnProperty(key) ? MAP[key] : `No translation found for "${ns}.${cat}.${key}"`
  })
}

export const convertTimeToDecimal = (time, type) => {
  const timeArray = time.split(':')
  return Number((parseInt(timeArray[0]) * 10000 +
    (parseInt(timeArray[1]) * 1/60) * 10000 +
    (parseInt(timeArray[2]) * 1/3600) * 1000 ).toFixed(0))
}