import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, Container, Table } from 'react-bootstrap'
import moment from 'moment'
import { IoWarningOutline } from 'react-icons/io5'

import { getNotApprovedDoctors } from 'actions/doctor'
import  FileIcon from 'assets/images/icons/file-icon.svg'

import FilterPanel from 'components/ui/common/filter'
import Pagination from 'components/ui/common/paginate'

import Footer from 'components/ui/footer'
import DoctorAvatar from 'components/ui/common/avatar'
import Specialty from 'components/ui/common/specialty'
import Loader from 'components/ui/common/loader'
import NoData from 'components/ui/common/noData'

import 'style/components/common/index.scss'

const DoctorRow = ({ _id, data: { profile, isEmpty = true }, created }) => {

	const history = useHistory()
	const { t }   = useTranslation(['translation'])

	const LABELS        = t('translation:GENERIC')
	const FORMATTING    = t('translation:FORMATTING')

	const registeredOn       = moment.utc(created).format(FORMATTING['DATE_FORMAT'])
	const registeredOnDescr  = moment.utc(created).format(FORMATTING['DATETIME_FORMAT_DESC'])

	const goToProfile = () => history.push(`/doctor-profile/${_id}`)

	return <tr onClick = { isEmpty ? () => {} : goToProfile }>
		<td>
			<DoctorAvatar
				avatar = { profile.avatar }
				gender = { profile.gender }
				name   = { `${profile.firstName} ${profile.lastName}` } />
		</td>
		<td className='doctor-specialty-list'>
			{
        isEmpty
        ? null
				: profile.speciality.map(s => <Specialty {...s} />)
			}
		</td>
		<td title = { registeredOnDescr }>
			{ registeredOn }
		</td>
		<td>
			{/* <Dot
				className='margin-right-5'
				size={25}
				color={status > 2 ? 'red': 'blue'}
				children={status} /> */}

			{ LABELS['AWAITING_REVIEW_REQUEST'] }
		</td>
		<td>
      {
        isEmpty
        ? <div title={LABELS['DOCTOR_PROFILE_INCOMPLETE']}>
            <IoWarningOutline /> {LABELS['WAITING_FOR_PROFILE']}
          </div>
        : <Link to={`/doctor-profile/${_id}`}><img alt='File:' src={FileIcon} className='file'/>
            { LABELS['REVIEW_REQUEST'] }
          </Link>
      }
		</td>
	</tr>
}

const DoctorsTable = ({ items }) => {
	const { t }  = useTranslation(['translation'])
	const LABELS = t('translation:GENERIC')

	return <Table hover>
		<thead>
			<tr>
				<th>{LABELS['DOCTOR']}</th>
				<th>{LABELS['SPECIALITY']}</th>
				<th>{LABELS['APPLIED']}</th>
				<th>{LABELS['STATUS']}</th>
				<th>&nbsp;</th>
			</tr>
		</thead>
		<tbody>
			{
				items.map((el, idx) => <DoctorRow key = {`doctor-list-item-${idx}`} {...el} />)
			}
		</tbody>
	</Table>
}

const Doctors = () => {
  const { t } = useTranslation(['translation', 'specialty'])

	const [currentPage, setCurrentPage]   = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

	const [fromDateFilter, setFromDateFilter] = useState(moment.utc().startOf('year'))
	const [toDateFilter, setToDateFilter]     = useState(new Date())
	const [categoryFilter, setCategoryFilter] = useState('')


  const changePage = ({ currentPage }) => {
    if(currentPage > 0) {
      setCurrentPage(currentPage)
    }
  }

	const dispatch = useDispatch()

	const doctorData = useSelector(s => s.doctor.doctorApprovals)

	if (!doctorData.isLoaded && !doctorData.isFetching) {
		dispatch(getNotApprovedDoctors())
	}

	let body = null

	if (!doctorData.isFetching) {
		const indexOfLastEntry  = currentPage * itemsPerPage
		const indexOfFirstEntry = indexOfLastEntry - itemsPerPage
    let currentEntries      = doctorData.data

    if (fromDateFilter) {
      currentEntries = currentEntries.filter(({ created }) => moment.utc(created).startOf('day').isSameOrAfter(fromDateFilter) )
    }

    if (toDateFilter) {
      currentEntries = currentEntries.filter(({ created }) => moment.utc(created).startOf('day').isSameOrBefore(toDateFilter) )
    }

    if (categoryFilter !== '') {
			currentEntries = currentEntries.filter(e => e.data.profile.speciality.map(e => e.type).includes(categoryFilter))
    }
    currentEntries = currentEntries.slice(indexOfFirstEntry, indexOfLastEntry)
		body = <DoctorsTable items = { currentEntries } />
	}

	const onFilterChange = (source, value) => {
		switch(source) {
			case 'fromDate':
				setFromDateFilter(moment.utc(value).toDate())
				break
			case 'toDate':
				setToDateFilter(moment.utc(value).toDate())
				break
			case 'category':
				setCategoryFilter(value)
        break
      default:
		}
	}

	return (
		<>
			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
						<Col md={10}>
							<h5>{ t('translation:GENERIC:PHYSICIAN_APPROVAL') }</h5>
						</Col>
						</Row>
						<Row className='filter-row'>
							<FilterPanel
								categories = { t('specialty:LIST') }
								onChange   = { onFilterChange }
								fromDate   = { moment.utc(fromDateFilter).format('YYYY-MM-DD') }
								category   = { categoryFilter }
								toDate     = { moment.utc(toDateFilter).format('YYYY-MM-DD') }
							/>
						</Row>
					</Card.Header>
					<Card.Body>
            <Loader {...doctorData}>
              <NoData data = { doctorData.data } wrapper = { false }>{ body }</NoData>
            </Loader>
					</Card.Body>
					<Card.Footer className='text-center'>
						<div className='d-inline-block'>
              <Pagination
                currentPage    = { currentPage }
                totalRecords   = { doctorData.data.length || 0 }
                pageLimit      = { itemsPerPage }
                pageNeighbours = { 1 }
                onPageChanged  = { changePage } />
						</div>
					</Card.Footer>
				</Card>
			</Container>
			<Footer />
		</>
	)
}

export default Doctors
