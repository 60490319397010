import React from 'react'
import * as BsIcons from 'react-icons/bs'
import * as GrIcons from 'react-icons/gr'
// import * as MdIcons from 'react-icons/md'
// import * as IoIcons from 'react-icons/io5'

export const SidebarData = [
	{
		label: 'DASHBOARD',
		path: '/',
		icon: <GrIcons.GrDashboard />,
		className: 'menu-button'
	},
	{
		label: 'DOCTOR_LIST',
		path: '/doctors',
		icon: <BsIcons.BsPeople />,
		className: 'menu-button'
	},
  {
		label: 'DOCTORS_STATS',
		path: '/doctors-stats',
		icon: <BsIcons.BsTable />,
		className: 'menu-button'
	},
	{
		label: 'PHYSICIAN_APPROVAL',
		path: '/doctor-approvals',
		icon: <BsIcons.BsEnvelope />,
		className: 'menu-button'
	}
	// {
	// 	title: 'Profile',
	// 	path: '/profile',
	// 	icon: <IoIcons.IoPersonCircle />,
	// 	className: 'menu-button'
	// }
];
