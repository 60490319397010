import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const NoData = ({ data, children, wrapper = true }) => {
  const { t }  = useTranslation(['translation'])

  if (!data || data.length === 0) {
    const noDataMessage = <Alert variant = 'info'>{ t('translation:GENERIC:NO_DATA') }</Alert>
    return wrapper ? <Container>{noDataMessage}</Container> : noDataMessage
  }
  return children
}
export default NoData