import React from 'react'

const hanldeError = gender => evt => {
	if (gender === 'man') {
		evt.currentTarget.src = 'https://www.w3schools.com/howto/img_avatar.png'
	} else {
		evt.currentTarget.src = 'https://www.w3schools.com/howto/img_avatar2.png'
	}
}

const DoctorAvatar = ({ avatar, name, gender = 'man' }) => {
	let label = null
	if (name) {
		label = <span>{name}</span>
	}
	return <div className='doctor-avatar'>
		<div className='img-container round box-52 border-green text-center'><img
      alt       = 'Avatar'
			className = 'image-48 '
			src       = { avatar }
			onError   = { hanldeError(gender) }
		/></div>
		{label}
	</div>
}

export default DoctorAvatar