import { ACTION_TYPES } from '../reducers/auth'

export const setAuthToken = (token, user) => ({
  type: ACTION_TYPES.SET_AUTH_TOKEN,
  token,
  user
})

export const resetAuthToken = () => ({
  type: ACTION_TYPES.RESET_AUTH_TOKEN
})


//RESET_AUTH_TOKEN