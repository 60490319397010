import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import SpecialtyIcon from 'assets/images/icons/specialty-icon.svg'
import SpecialtyDocuments from './documents'

const NOW = moment()

const SpecialtyEntry = ({ type, category, gradeLevel, start, documents = [] }) => {

	const { t }         = useTranslation(['translation', 'specialty', 'category', 'degree'])
	const LABELS        = t('translation:GENERIC')
	const FORMATTING    = t('translation:FORMATTING')
	const SPECIALTY_MAP = t('specialty:MAP')
	const CATEGORY_MAP  = t('category:MAP')
	const DEGREE_MAP    = t('degree:MAP')

	let documentsCmp = null
	if (documents && documents.length > 0) {
		documentsCmp = <>
			<hr />
			<SpecialtyDocuments documents = { documents } />
		</>
	}
	const startDate = moment.utc(start).format(FORMATTING['DATE_FORMAT'])
	const experienceDuration = moment.duration(NOW.diff(moment(start)))

	return <div className='doctor-profile-info-box'>
			<Row>
				<Col md={4}>
					<div className='profile-image'>
						<img
              alt        = ''
							src       = { SpecialtyIcon }
							className = 'round'
						/>
					</div>
					<div>
						<div className='section-header'>
              { SPECIALTY_MAP[type] }
						</div>
					</div>
				</Col>

				<Col>
					<div className='section-subheader'>
						{ LABELS['GRADE_LEVEL'] }
					</div>
					{DEGREE_MAP[gradeLevel]}
				</Col>

				<Col>
					<div className='section-subheader'>
						{ LABELS['CATEGORY'] }
					</div>
					{CATEGORY_MAP[category]}
				</Col>

				<Col>
					<div className='section-subheader'>
						{ LABELS['PRACTICE_START'] }
					</div>
					{startDate}
				</Col>

				<Col>
					<div className='section-subheader'>
						{ LABELS['EXPERIENCE'] }
					</div>
					{/* {NOW.diff(moment(start), 'years')} */}
					{experienceDuration.years()} { LABELS['YEARS'] } {experienceDuration.months()} { LABELS['MONTHS'] }
				</Col>
			</Row>
			{ documentsCmp }
	</div>
}

const Specialty = ({ specialties }) => {
	const { t } = useTranslation(['translation'])
	const LABELS = t('translation:GENERIC')

	return <div className='doctor-profile'>
		<h5 className='header'>{ LABELS['SPECIALTY'] }</h5>
		{specialties.map((specialty, index) => <SpecialtyEntry key={`specialty-entry-${index}`} {...specialty} />)}
	</div>
}

export default Specialty

