
import { Row, Col } from 'react-bootstrap'
import FileIcon from 'assets/images/icons/file-icon.svg'

const Documents = ({ documents }) => (
	<Row className='doctor-profile-specialty-files'>
		<Col>
			{documents.map(({ url, name }, idx) => <a
        key       = {`doc-${idx}-${name}`}
				className = 'file-icon'
        target    = '_blank'
        rel       = 'noreferrer'
				href      = { url }
				title     = { name }>
				<img alt = '' src={FileIcon} /> <span>{ name }</span>
			</a>)}
		</Col>
	</Row>
)

export default Documents