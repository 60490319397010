const defaultState = {
  isLoaded   : false,
  isFetching : false,
  data       : [],
  error      : null
}

export const ACTION_TYPES = {
  STATS_REQ  : 'STATS_REQ',
  STATS_SUCC : 'STATS_SUCC',
  STATS_FAIL : 'STATS_FAIL'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.STATS_REQ:
      return {
        ...state,
        isFetching : true,
        isLoaded   : false,
        data       : [],
        error      : null
      }
    case ACTION_TYPES.STATS_SUCC:
      return {
        ...state,
        isFetching : false,
        isLoaded   : true,
        data       : action.data,
        error      : null
      }
    case ACTION_TYPES.STATS_FAIL:
      return {
        ...state,
        isFetching : false,
        isLoaded   : false,
        data       : [],
        error      : action.error
      }
    default:
      return state
  }
}