const defaultState = {
  menu: {
    isCollapsed: true
  }
}

export const ACTION_TYPES = {
  TOGGLE: 'toggle'
}

export default function uiReducer(state = defaultState, { type }) {
  switch(type) {
    case ACTION_TYPES.TOGGLE:
      return {
        ...state,
        menu: {
          ...state.menu,
          isCollapsed : !state.menu.isCollapsed
        }
      }
    default:
      return state
  }
}