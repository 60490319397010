import React from 'react'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DoctorAvatar from 'components/ui/common/avatar'

const PersonalInfo = ({
	lastName, firstName, avatar, country, city, birthDate, gender, phone, email
}) => {
	const { t } = useTranslation(['translation', 'cities', 'gender', 'countries', 'category'])
	const LABELS        = t('translation:GENERIC')
	const FORMATTING    = t('translation:FORMATTING')
	const COUNTRIES     = t('countries:COUNTRIES')
	const citiesOptions = t('cities:CITIES')
  const localizedCity = citiesOptions[city - 1].name
	const dob = moment.utc(birthDate).format(FORMATTING['DATE_FORMAT'])

	return (
		<div className='doctor-profile'>
			<h5 className='header'>{LABELS['PERSONAL_INFO']}</h5>
			<div className='doctor-profile-info-box'>
				<Row>
					<Col md={4}>
						<div className='profile-image'>
							<DoctorAvatar
								avatar = { avatar.url }
								gender = { gender }
							/>
						</div>
						<div>
							<div className='section-header'>{firstName} {lastName}</div>
							{localizedCity}, {COUNTRIES[country]}
						</div>
					</Col>
					<Col>
						<div className='section-subheader'>{LABELS['DOB']}</div>
						{dob}
					</Col>
					<Col>
						<div className='section-subheader'>{LABELS['GENDER']}</div>
						{t(`gender:${gender}`)}
					</Col>
					<Col>
						<div className='section-subheader'>{LABELS['PHONE_NUMBER']}</div>
						{phone}
					</Col>
					<Col>
						<div className='section-subheader'>{LABELS['EMAIL']}</div>
						{email.toLowerCase()}
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default PersonalInfo
