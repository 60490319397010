import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from 'App'
import store from 'store'
import { Auth0Provider } from '@auth0/auth0-react'

import { auth0ProviderOptions } from 'utils/auth0/authProviderOptions'
import 'typeface-roboto'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'style/index.scss'
import 'utils/i18n/'

ReactDOM.render(
	<React.StrictMode>
		<Auth0Provider {...auth0ProviderOptions}>
			<Provider store = { store }>
				<Suspense fallback={<div></div>}>
					<App />
				</Suspense>
			</Provider>
		</Auth0Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
