import { config } from '../config'

export const auth0ProviderOptions = {
	domain: config.auth0Domain,
	clientId: config.auth0ClientId,
	audience: config.auth0Audience,
	redirectUri: config.auth0RedirectURI,
	useRefreshTokens: true,
	// cacheLocation: 'localstorage',
	login_hint: 'user-password'
}
