import moment from 'moment'

import { ACTION_TYPES } from 'reducers/doctorStats'
import { ACTION_TYPES as DOCTORS_ACTION_TYPES} from 'reducers/doctorsStats'

export const getDoctorStats = (doctorId, fromDate, toDate) => async (dispatch, getStore, { api }) => {
  dispatch({ type: ACTION_TYPES.GET_DOCTOR_STATS_REQ })
  const { auth: { isAuthorized, token } } = getStore()

  if (isAuthorized && token) {
    try {
      const resp = await api.post(`/api/admin/doctor/stats?fromDate=${fromDate}&toDate=${toDate}`, {
        doctorId
      }, {
        headers : {
          Authorization: `Bearer ${token}`
        }
      })

      dispatch({
        type: ACTION_TYPES.GET_DOCTOR_STATS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.GET_DOCTOR_STATS_FAIL,
        error
      })
    }
  }
}

export const getDoctorsStats = (fromDate, toDate) => async (dispatch, getStore, { api }) => {
  dispatch({ type: DOCTORS_ACTION_TYPES.GET_DOCTORS_STATS_REQ })
  const { auth: { isAuthorized, token } } = getStore()

  if (isAuthorized && token) {
    try {
      const resp = await api.get(`/api/admin/doctors/worklog?fromDate=${moment.utc(fromDate).format('YYYY-MM-DD')}&toDate=${moment.utc(toDate).format('YYYY-MM-DD')}`, {
        headers : {
          Authorization: `Bearer ${token}`
        }
      })

      dispatch({
        type: DOCTORS_ACTION_TYPES.GET_DOCTORS_STATS_SUCC,
        data: resp.data.data
      })
    } catch (error) {
      dispatch({
        type: DOCTORS_ACTION_TYPES.GET_DOCTORS_STATS_FAIL,
        error
      })
    }
  }
}