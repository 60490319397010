const defaultState = {
  isLoaded   : false,
  isFetching : false,
  data       : [],
  error      : null
}

export const ACTION_TYPES = {
  DOCS_REQ  : 'ACTIVE_DOCS_REQ',
  DOCS_SUCC : 'ACTIVE_DOCS_SUCC',
  DOCS_FAIL : 'ACTIVE_DOCS_FAIL'
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case ACTION_TYPES.DOCS_REQ:
      return {
        ...state,
        isFetching : true,
        isLoaded   : false,
        data       : [],
        error      : null
      }
    case ACTION_TYPES.DOCS_SUCC:
      return {
        ...state,
        isFetching : false,
        isLoaded   : true,
        data       : action.data,
        error      : null
      }
    case ACTION_TYPES.DOCS_FAIL:
      return {
        ...state,
        isFetching : false,
        isLoaded   : false,
        data       : [],
        error      : action.error
      }
    default:
      return state
  }
}